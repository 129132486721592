import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Layout>
      <h3>404:</h3>
      <h3>Stranica nije pronađena</h3>
      <p>
        Tražena stranica ne postoji. Molim vas da se vratite na početnu{" "}
        <Link to="/">stranicu.</Link>
      </p>
    </Layout>
  </>
)

export default NotFoundPage
